// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-basin-js": () => import("./../../../src/pages/basin.js" /* webpackChunkName: "component---src-pages-basin-js" */),
  "component---src-pages-biyografi-js": () => import("./../../../src/pages/biyografi.js" /* webpackChunkName: "component---src-pages-biyografi-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-galeri-js": () => import("./../../../src/pages/galeri.js" /* webpackChunkName: "component---src-pages-galeri-js" */),
  "component---src-pages-iletisim-js": () => import("./../../../src/pages/iletisim.js" /* webpackChunkName: "component---src-pages-iletisim-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-koleksiyonlar-js": () => import("./../../../src/pages/koleksiyonlar.js" /* webpackChunkName: "component---src-pages-koleksiyonlar-js" */),
  "component---src-pages-projeler-binder-projesi-js": () => import("./../../../src/pages/projeler/binder-projesi.js" /* webpackChunkName: "component---src-pages-projeler-binder-projesi-js" */),
  "component---src-pages-projeler-diyanet-projesi-js": () => import("./../../../src/pages/projeler/diyanet-projesi.js" /* webpackChunkName: "component---src-pages-projeler-diyanet-projesi-js" */),
  "component---src-pages-projeler-forelli-projesi-js": () => import("./../../../src/pages/projeler/forelli-projesi.js" /* webpackChunkName: "component---src-pages-projeler-forelli-projesi-js" */),
  "component---src-pages-projeler-gubretas-projesi-js": () => import("./../../../src/pages/projeler/gubretas-projesi.js" /* webpackChunkName: "component---src-pages-projeler-gubretas-projesi-js" */),
  "component---src-pages-projeler-iett-projesi-js": () => import("./../../../src/pages/projeler/iett-projesi.js" /* webpackChunkName: "component---src-pages-projeler-iett-projesi-js" */),
  "component---src-pages-projeler-iga-projesi-js": () => import("./../../../src/pages/projeler/iga-projesi.js" /* webpackChunkName: "component---src-pages-projeler-iga-projesi-js" */),
  "component---src-pages-projeler-iski-projesi-js": () => import("./../../../src/pages/projeler/iski-projesi.js" /* webpackChunkName: "component---src-pages-projeler-iski-projesi-js" */),
  "component---src-pages-projeler-js": () => import("./../../../src/pages/projeler.js" /* webpackChunkName: "component---src-pages-projeler-js" */),
  "component---src-pages-projeler-kuveytturk-projesi-js": () => import("./../../../src/pages/projeler/kuveytturk-projesi.js" /* webpackChunkName: "component---src-pages-projeler-kuveytturk-projesi-js" */),
  "component---src-pages-projeler-maarif-projesi-js": () => import("./../../../src/pages/projeler/maarif-projesi.js" /* webpackChunkName: "component---src-pages-projeler-maarif-projesi-js" */),
  "component---src-pages-projeler-ortadogu-projesi-js": () => import("./../../../src/pages/projeler/ortadogu-projesi.js" /* webpackChunkName: "component---src-pages-projeler-ortadogu-projesi-js" */),
  "component---src-pages-projeler-turk-hava-yollari-projesi-js": () => import("./../../../src/pages/projeler/turk-hava-yollari-projesi.js" /* webpackChunkName: "component---src-pages-projeler-turk-hava-yollari-projesi-js" */),
  "component---src-pages-projeler-turkiye-finans-projesi-js": () => import("./../../../src/pages/projeler/turkiye-finans-projesi.js" /* webpackChunkName: "component---src-pages-projeler-turkiye-finans-projesi-js" */),
  "component---src-pages-projeler-tusas-motor-sanayii-js": () => import("./../../../src/pages/projeler/tusas-motor-sanayii.js" /* webpackChunkName: "component---src-pages-projeler-tusas-motor-sanayii-js" */),
  "component---src-pages-projeler-tusas-projesi-js": () => import("./../../../src/pages/projeler/tusas-projesi.js" /* webpackChunkName: "component---src-pages-projeler-tusas-projesi-js" */),
  "component---src-pages-projeler-ulastirma-bakanligi-projesi-js": () => import("./../../../src/pages/projeler/ulastirma-bakanligi-projesi.js" /* webpackChunkName: "component---src-pages-projeler-ulastirma-bakanligi-projesi-js" */),
  "component---src-pages-projeler-vakif-katilim-projesi-js": () => import("./../../../src/pages/projeler/vakif-katilim-projesi.js" /* webpackChunkName: "component---src-pages-projeler-vakif-katilim-projesi-js" */),
  "component---src-pages-projeler-yildiz-entegre-js": () => import("./../../../src/pages/projeler/yildiz-entegre.js" /* webpackChunkName: "component---src-pages-projeler-yildiz-entegre-js" */),
  "component---src-pages-projeler-ziraat-katilim-projesi-js": () => import("./../../../src/pages/projeler/ziraat-katilim-projesi.js" /* webpackChunkName: "component---src-pages-projeler-ziraat-katilim-projesi-js" */),
  "component---src-pages-sergiler-js": () => import("./../../../src/pages/sergiler.js" /* webpackChunkName: "component---src-pages-sergiler-js" */),
  "component---src-templates-basin-js": () => import("./../../../src/templates/basin.js" /* webpackChunkName: "component---src-templates-basin-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-sergi-sayfasi-js": () => import("./../../../src/templates/sergi-sayfasi.js" /* webpackChunkName: "component---src-templates-sergi-sayfasi-js" */)
}

